import React, { useEffect, useRef } from 'react'
import ContentLoader from "react-content-loader"
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const MyLoader = (props) => (
<ContentLoader
   speed={2}
   width={511}
   height={72}
   viewBox="0 0 511 72"
   backgroundColor="#162333"
   foregroundColor="#031B1E"
   {...props}
   >
   <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
   <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
   <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
   <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
   <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
   <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
   <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
   <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
</ContentLoader>
)
function Services() {
const gridRef = useRef(null);
useGSAP(() => {
gsap.registerPlugin(ScrollTrigger)
const gridItems = gridRef.current.children;
console.log(gridItems)
const tl = gsap.timeline({
// yes, we can add it to an entire timeline!
scrollTrigger: {
trigger: ".grid-container",
pin: true, // pin the trigger element while active
start: "top top", // when the top of the trigger hits the top of the viewport
end: "+=250%", // end after scrolling 500px beyond the start
scrub: .3, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
},
})
tl.from("#top-svg", {
opacity: 0,
x: -100,
stagger: 0.3,
pin: true,
end: "250%",
duration: 0.5,
scrollTrigger: {
trigger: ".grid-container",
start: "top top", // when the top of the trigger hits the top of the viewport
scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
// markers: true
},
});
tl.from("#bottom-svg", {
opacity: 0,
x: 100,
stagger: 0.3,
pin: true,
end: "250%",
duration: 0.5,
scrollTrigger: {
trigger: ".grid-container",
start: "top top", // when the top of the trigger hits the top of the viewport
scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
// markers: true
},
});
tl.from(gridItems, {
opacity: 0,
y: 20,
stagger: 0.3,
duration: 0.5,
scrollTrigger: {
trigger: ".grid-container",
start: "top-=30% top", // when the top of the trigger hits the top of the viewport
scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
// markers: true
},
});
tl.to(gridItems, {
opacity: 1,
y: 0,
stagger: 0.3,
duration: 0.5,
scrollTrigger: {
trigger: ".grid-container",
start: "top-=30% top", // when the top of the trigger hits the top of the viewport
scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
},
});
}, [])
return (
<div id="services" className='relative'>
   <div className='max-w-[1200px] grid-container pt-[109px] pb-[190px] mx-auto'>
      {/*
      <svg id="top-svg" className='absolute left-[-30%] top-[-20%]' width="489" height="511" viewBox="0 0 489 511" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M223.023 279.855L223.023 260.047C223.023 251.763 216.307 245.047 208.023 245.047L-59.9999 245.047" stroke="white" stroke-opacity="0.2" stroke-dasharray="8 8"/>
         <g filter="url(#filter0_f_182_75)">
            <ellipse cx="198.624" cy="255.684" rx="89.5" ry="55" transform="rotate(179.436 198.624 255.684)" fill="white" fill-opacity="0.3"/>
         </g>
         <circle cx="218.534" cy="249.889" r="3" transform="rotate(179.436 218.534 249.889)" stroke="white"/>
         <defs>
            <filter id="filter0_f_182_75" x="-90.8737" y="0.679932" width="578.995" height="510.009" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"/>
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
               <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_182_75"/>
            </filter>
         </defs>
      </svg>
      <svg
         id="bottom-svg"
         className='absolute right-[-30%] bottom-[-20%]'
         xmlns="http://www.w3.org/2000/svg"
         width="522"
         height="510"
         fill="none"
         viewBox="0 0 522 510"
         >
         <path
            stroke="#fff"
            strokeDasharray="8 8"
            strokeOpacity="0.2"
            d="M264.997 171.788v78.609c0 8.284 6.716 15 15 15h267.037"
            ></path>
         <g filter="url(#filter0_f_182_78)">
            <ellipse
               cx="289.5"
               cy="255"
               fill="#fff"
               fillOpacity="0.3"
               rx="89.5"
               ry="55"
               ></ellipse>
         </g>
         <circle cx="269.358" cy="261.288" r="3" stroke="#fff"></circle>
         <defs>
            <filter
               id="filter0_f_182_78"
               width="579"
               height="510"
               x="0"
               y="0"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
               >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                  ></feBlend>
               <feGaussianBlur
                  result="effect1_foregroundBlur_182_78"
                  stdDeviation="100"
                  ></feGaussianBlur>
            </filter>
         </defs>
      </svg>
      */}
      <div ref={gridRef} className='lg:grid flex flex-col  lg:max-h-[636px] overflow-hidden grid-cols-12 grid-rows-12 gap-x-[38px] gap-y-[38px] gap-[38px] px-8'>
         {/* Tickets */}
         <div className='rounded-[25px] grid-item translate-y-[00%] p-8 border border-white/10 col-span-4 row-span-7  bg-gradient-to-b from-[#031B1E]/0  to-[#031B1E] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-500 flex flex-col items-start gap-y-[30px] '>
            <svg
               className='mx-auto block w-full'
               xmlns="http://www.w3.org/2000/svg"
               width="234"
               height="110"
               fill="none"
               viewBox="0 0 234 110"
               >
               <path
                  stroke="#fff"
                  strokeDasharray="9 9"
                  strokeOpacity="0.2"
                  strokeWidth="2"
                  d="M8.805 102.434v0c60.596-57.578 155.516-58.116 216.761-1.23l1.323 1.23"
                  ></path>
               <circle
                  cx="117.534"
                  cy="47.067"
                  r="7.871"
                  fill="#3ECF67"
                  transform="rotate(179.436 117.534 47.067)"
                  ></circle>
               <mask
               id="mask0_157_59"
               style={{ maskType: "alpha" }}
               width="12"
               height="13"
               x="112"
               y="41"
               maskUnits="userSpaceOnUse"
               >
               <path fill="#D9D9D9" d="M112.065 41.597H123.63V53.162H112.065z"></path>
               </mask>
               <g mask="url(#mask0_157_59)">
                  <path
                     fill="#fff"
                     d="M116.667 50.27l-2.747-2.746.687-.686 2.06 2.06 4.421-4.422.687.687-5.108 5.108z"
                     ></path>
               </g>
               <circle
                  cx="8.492"
                  cy="100.762"
                  r="7.871"
                  fill="#0D3F21"
                  transform="rotate(179.436 8.492 100.762)"
                  ></circle>
               <mask
               id="mask1_157_59"
               style={{ maskType: "alpha" }}
               width="12"
               height="12"
               x="3"
               y="95"
               maskUnits="userSpaceOnUse"
               >
               <path fill="#D9D9D9" d="M3.022 95.292H14.587V106.857H3.022z"></path>
               </mask>
               <g mask="url(#mask1_157_59)">
                  <path
                     fill="#fff"
                     fillOpacity="0.2"
                     d="M7.624 103.966l-2.746-2.747.686-.686 2.06 2.06 4.422-4.422.686.687-5.108 5.108z"
                     ></path>
               </g>
               <circle
                  cx="225.751"
                  cy="101.295"
                  r="7.871"
                  fill="#0D3F21"
                  transform="rotate(179.436 225.751 101.295)"
                  ></circle>
               <mask
               id="mask2_157_59"
               style={{ maskType: "alpha" }}
               width="12"
               height="13"
               x="220"
               y="95"
               maskUnits="userSpaceOnUse"
               >
               <path fill="#D9D9D9" d="M220.281 95.825H231.846V107.39H220.281z"></path>
               </mask>
               <g mask="url(#mask2_157_59)">
                  <path
                     fill="#fff"
                     fillOpacity="0.2"
                     d="M224.883 104.499l-2.747-2.747.687-.687 2.06 2.06 4.421-4.42.687.686-5.108 5.108z"
                     ></path>
               </g>
               <circle
                  cx="181.969"
                  cy="65.241"
                  r="7.871"
                  fill="#246F42"
                  transform="rotate(179.436 181.969 65.24)"
                  ></circle>
               <mask
               id="mask3_157_59"
               style={{ maskType: "alpha" }}
               width="13"
               height="13"
               x="176"
               y="59"
               maskUnits="userSpaceOnUse"
               >
               <path fill="#D9D9D9" d="M176.499 59.771H188.064V71.336H176.499z"></path>
               </mask>
               <g mask="url(#mask3_157_59)">
                  <path
                     fill="#fff"
                     fillOpacity="0.6"
                     d="M181.101 68.445l-2.747-2.747.687-.687 2.06 2.06 4.421-4.42.687.686-5.108 5.108z"
                     ></path>
               </g>
               <circle
                  cx="50.622"
                  cy="63.295"
                  r="7.871"
                  fill="#246F42"
                  transform="rotate(179.436 50.622 63.295)"
                  ></circle>
               <mask
               id="mask4_157_59"
               style={{ maskType: "alpha" }}
               width="12"
               height="13"
               x="45"
               y="57"
               maskUnits="userSpaceOnUse"
               >
               <path fill="#D9D9D9" d="M45.152 57.825H56.717V69.39H45.152z"></path>
               </mask>
               <g mask="url(#mask4_157_59)">
                  <path
                     fill="#fff"
                     fillOpacity="0.6"
                     d="M49.754 66.5l-2.746-2.748.686-.686 2.06 2.06 4.422-4.421.686.686-5.108 5.108z"
                     ></path>
               </g>
            </svg>
            <p className='font-bold text-[20px] text-white'>Grab Tickets in <span className='text-[#00D3EF]'>Seconds</span></p>
            <p className='text-[18px] text-[#A0A0A0]'>We offer detailed guides for each release, experienced and active team, custom monitors and tools and much more.</p>
         </div>
         {/* Waitlist */}
         <div className='rounded-[25px] w-full  grid-item card-1 p-8 border border-white/10 col-start-5 col-span-3 row-span-7 bg-gradient-to-b from-[#031B1E]/0  to-[#031B1E] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-500 flex flex-col'>
            <p className='font-bold text-[20px] text-center text-white'>Recent <span className='text-[#00D3EF]'>Success</span></p>
            <a href="https://dash.nootifyt.eu/">
                <img className='lg:absolute bottom-0 left-0 gap-y-[10px]' src="/images/success.png"/>
            </a>
         </div>
         {/* Phone */}
         <div className='rounded-[25px] grid-item relative  pt-8 pl-8 pr-8 border border-white/10 col-start-8 col-span-6 row-span-12 bg-gradient-to-b from-[#031B1E]/0  to-[#031B1E] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-500'>
            <div className='flex flex-row flex-wrap items-center justify-center gap-[20px]'>
               <img className='w-[179px]' src="/images/fast.png"/>
               <img className='w-[195px]' src="/images/info.png"/>
               <img className='w-[214px]' src="/images/community.png"/>
            </div>
            <img className='lg:absolute bottom-0 left-0' src="/images/phone.png"/>
         </div>
         {/* Guide */}
         <div className='rounded-[25px] grid-item p-8 border border-white/10 col-span-7 row-start-8 row-span-5 bg-gradient-to-b from-[#031B1E]/0  to-[#031B1E] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-500'>
            <p className='font-bold text-[20px] text-center text-white'>Exclusive Ticket <span className='text-[#00D3EF]'>Guides</span></p>
            <img className='block mx-auto mt-[16px]' src="/images/guides.png"/>
            {/*
            <MyLoader/>
            */}
            <a href="https://dash.nootifyt.eu/">
            <img className='w-[230px] block mx-auto gap-y-[10px]' src="/images/claim.png"/>
            </a>
         </div>
      </div>
   </div>
</div>
)
}
export default Services
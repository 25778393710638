import React from 'react'
import { FaTwitter, FaDiscord } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";



function Footer() {
  return (
    <div className=' py-[58px]'>
        <div className='max-w-7xl px-4 mx-auto items-center flex flex-col md:flex-row justify-between'>
            <div className='flex flex-col gap-y-[20px] items-center lg:items-start'>
            <img
            src="/images/logo.png"
            className="md:w-[227px]"
            alt="NootifyT Logo"
          />
          <p className='max-w-[290px] text-[#A2A2A2] text-[15px] font-medium mt-2 opacity-75 md:text-left text-center mx-auto md:mx-0 transition-all'>
              <a href="mailto:support@nootifyt.eu" target="_blank">support@nootifyt.eu</a><br/>&copy; {new Date().getFullYear()} NTF s. r. o. | VAT ID: CZ10853031 <br/>
              <a href="https://tos.nootify.eu/termst.html" target="_blank">Terms of Service</a> | <a href="https://tos.nootify.eu/privacyt.html" target="_blank">Privacy Policy</a>
          </p>
            </div>
            <div className='flex flex-row gap-x-[46px] my-[20px]'>
                <a href="/" className="text-[#fff] text-[10px] md:text-[15px] font-medium mt-2 opacity-75 hover:text-white hover:opacity-100  transition-all cursor-pointer">Home</a>
                <a href="#services" className="text-[#fff] text-[10px] md:text-[15px] font-medium mt-2 opacity-75 hover:text-white hover:opacity-100  transition-all cursor-pointer">Our Services</a>
                <a href="#about" className="text-[#fff] text-[10px] md:text-[15px] font-medium mt-2 opacity-75 hover:text-white hover:opacity-100  transition-all cursor-pointer">About us</a>
                <a href="#faq" className="text-[#fff] text-[10px] md:text-[15px] font-medium mt-2 opacity-75 hover:text-white hover:opacity-100  transition-all cursor-pointer">FAQ</a>
            </div>
            <div className='flex flex-row gap-x-[20px]'>
                <a  className='w-[42px] h-[42px] bg-[#182831] flex flex-col items-center justify-center rounded-full' target='_blank' href="https://www.instagram.com/nootifyt/">
                    <FaInstagram color="white" size={20}/>
                </a>
                <a className='w-[42px] h-[42px] bg-[#182831] flex flex-col items-center justify-center rounded-full' target='_blank' href="https://twitter.com/NootifyT">
                    <FaXTwitter color="white" size={20}/>
                </a>
            </div>
        </div>
{/*         <div className='max-w-[95%] w-full h-[1px] bg-white/10 my-[36px] mx-auto'></div> */}
{/*         <div className='max-w-7xl mx-auto items-center flex flex-col gap-y-[20px] md:flex-row justify-between'> */}
{/*             <p className='text-[13px] text-[#a2a2a2]'>© Copyright {new Date().getFullYear()},  NootifyT</p> */}
{/*             <div className='flex flex-row gap-x-[46px]'> */}
{/*                 <p className="text-[#9DA0BD] text-[15px] font-medium mt-2 opacity-75 hover:text-[#5F55F9] hover:opacity-100 hover:translate-y-[-3px] transition-all cursor-pointer">Privacy Policy</p> */}
{/*                 <p className="text-[#9DA0BD] text-[15px] font-medium mt-2 opacity-75 hover:text-[#5F55F9] hover:opacity-100 hover:translate-y-[-3px] transition-all cursor-pointer">Terms and Conditions</p> */}
{/*             </div> */}
{/*         </div> */}
    </div>
  )
}

export default Footer
import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { ChevronDownIcon } from "@radix-ui/react-icons";

function Faq() {
  return (
    <div
      id="faq"
      className="mx-auto px-4 py-[40px] border-0 border-white/20"
    >
      <img id="blur" src="/images/header-blur.png" className='z-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'/>
      <div className="max-w-7xl mx-auto px-4">
        <h6 className="text-[36px] text-center text-[#F7F6FF] mb-[60px] font-semibold text-white/60 bg-clip-text">
          Frequently Asked Questions
        </h6>

        <Accordion.Root
          className="AccordionRoot w-full space-y-[34px]"
          type="single"
          collapsible
        >
          
          <Accordion.Item className="AccordionItem w-full border border-white/10 rounded-[8px] " value="item-2">
            <AccordionTrigger className="text-white md:text-[20px] border-none w-full text-[14px] text-left font-medium border-b pb-4 border-white/20">
            What is NootifyT about?
            </AccordionTrigger>
            <AccordionContent className="text-[#A0A0A0] text-left w-full p-4  mb-2 pt-4 AccordionContent">
            NootifyT helps you secure tickets for high-demand events, like concerts or sports games, at their retail price.
            </AccordionContent>
          </Accordion.Item>

          <Accordion.Item className="AccordionItem w-full border border-white/10 rounded-[8px] " value="item-3">
            <AccordionTrigger className="text-white md:text-[20px] border-none w-full text-[14px] text-left font-medium border-b pb-4 border-white/20">
              Why should I join NootifyT?
            </AccordionTrigger>
            <AccordionContent className="text-[#A0A0A0] text-left w-full p-4  mb-2 pt-4 AccordionContent">
            Join NootifyT, powered by Nootify - the biggest sneaker cookgroup in the EU. Whether you're starting out or you're expirenced, you will gain invaluable benefit from real-time market alerts, exclusive tools, and access to a network of experienced members ready to share their strategies. Our community, offers exceptional support, guaranteeing that you're equipped with knowledge in the ticket world. Join us and elevate your ticket game.
            </AccordionContent>
          </Accordion.Item>

          <Accordion.Item className="AccordionItem w-full border border-white/10 rounded-[8px] " value="item-4">
            <AccordionTrigger className="text-white md:text-[20px] border-none w-full text-[14px] text-left font-medium border-b pb-4 border-white/20">
            What are the key strategies?
            </AccordionTrigger>
            <AccordionContent className="text-[#A0A0A0] text-left w-full p-4  mb-2 pt-4 AccordionContent">
            Successful ticket buying builds on understanding market dynamics, choosing the right events, and timing your purchases strategically. Utilizing platforms and tools is also crucial. In our group, we provide all the necessary resources, guidance, support, and the newest tools to help you master these strategies and succeed in the ticket market.
            </AccordionContent>
          </Accordion.Item>
        </Accordion.Root>
      </div>
    </div>
  );
}

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader w-full">
      <Accordion.Trigger
        className={classNames("AccordionTrigger w-full flex flex-row justify-between p-4", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <div  className="drop-bg">
          <ChevronDownIcon className="AccordionChevron inline-block" aria-hidden />
        </div>
        {/* <img src="/images/plus.png"/> */}
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
);

export default Faq;

import logo from './favicon.ico';
import './App.css';
import { Button } from 'flowbite-react';
import Nav from './components/Nav';
import Header from './components/Header';
import Support from './components/Support';
import Services from './components/Services';
import About from './components/About';
import Marquee from './components/Marquee';
import Subfooter from './components/Subfooter';
import Faq from './components/Faq';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App bg-[#030E14]">
      <Nav/>
      <Header/>
      <Support/>
      <Services/>
      <About/>
      <Marquee/>
      <Faq/>
      <Subfooter/>
      <Footer/>
    </div>
  );
}

export default App;

import React from 'react'

function Support() {
  return (
    <div className='bg-[#0A1921] w-screen border border-white/10 border-l-0 border-r-0'>
        <div className='flex flex-row mx-auto items-center justify-between px-4 max-w-[625px]'>
            <p className='text-[#A0A0A0] text-[15px] hidden lg:block '>Who we support</p>
            <img className='w-[63px]' src="/images/stubhub.png"/>
            <img className='w-[72px]' src="/images/viagogo.png"/>
            <img className='w-[89px]' src="/images/eventim.png"/>
            <img className='w-[119px]' src="/images/ticket.png"/>
        </div>
    </div>
  )
}

export default Support
import React from 'react'

function Subfooter() {
  return (
    <div className='py-[20px] pt-[38px] border border-white/10 bg-gradient-to-r from-[#0A1921]  to-[#0A1921]/0'>
        <div className='max-w-[1200px] mx-auto flex lg:flex-row flex-col items-center justify-center lg:justify-between'>
            <h6 className=' text-[18px] lg:text-[30px] lg:text-left text-center inter font-semibold text-white'><span className='text-[#00D3EF] stapel mr-4'>LEVEL UP</span>Your Ticket Game</h6>
            <a href="https://dash.nootifyt.eu/">
            <img className='w-[230px]' src="/images/get-started.png"/>
            </a>
        </div>
    </div>
  )
}

export default Subfooter
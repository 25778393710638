import React from 'react'

function About() {
  return (
    <div id="about" className='about relative pt-[63px] px-8 pb-[95px]'>
        {/* <img  src="/images/JoinToday.png" className='absolute top-[3%] lg:top-[6%] w-[300px] left-[22.5%] lg:left-[40%] max-w-[90%]'/> */}
        {/* <img  src="/images/JoinToday.png" className=' block mx-auto w-[300px] mb-[-2.5%] max-w-[90%]'/> */}
        <h1 className='stapel text-white text-[37px] text-center'> <span className='text-[#00D3EF]'>ABOUT</span> US</h1>
        {/* <p className='text-[#A0A0A0] text-[19px] max-w-[540px] text-center mx-auto mb-[43px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> */}
        <div className='flex flex-row w-full justify-center gap-[55px] flex-wrap'>
        <div className='rounded-[25px] w-full md:w-[352px] justify-between pt-8  p-4 border border-white/10 bg-gradient-to-b from-[#031B1E]/0  to-[#031B1E] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-500 flex flex-col items-center justify-center'>
            <img src="/images/globe.png"/>
<div className='flex flex-row justify-between text-center items-center gap-x-[40px] gap-y-[10px] items-center'>
    <p className='font-normal text-[18px] text-white/80'>We cover <span className='font-bold text-[#00D3EF]'>worldwide</span> ticket releases and deals.</p>
</div>
        </div>
        <div className='rounded-[25px] w-full md:w-[352px] justify-between pt-8  p-4 border border-white/10 bg-gradient-to-b from-[#031B1E]/0  to-[#031B1E] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-500 flex flex-col items-center justify-center'>
            <img src="/images/users.png"/>
<div className='flex flex-row justify-between text-center items-center gap-x-[40px] gap-y-[10px] items-center'>
    <p className='text-white/80 items-center font-normal text-[18px]'><span className='font-bold text-[#00D3EF]'>NootifyT</span> is run by the experienced team behind <a href="https://nootify.eu/" target="_blank"><span className='font-bold text-[#F0BA0B]'>Nootify</span></a>.</p>
    {/* <p className=' text-[#A0A0A0] font-normal text-[19px]'> the experienced team</p> */}
</div>
        </div>
        <div className='rounded-[25px] w-full md:w-[352px] justify-between pt-8  p-4 border border-white/10 bg-gradient-to-b from-[#031B1E]/0  to-[#031B1E] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-500 flex flex-col items-center justify-center'>
            <img src="/images/chart.png"/>
<div className='flex flex-row justify-between text-center items-center gap-x-[40px] gap-y-[10px] items-center'>
<p className='text-white/80 text-[18px]'>Over 50,000 € in secured tickets.<br/>Step up your ticket game with us.</p>
</div>
        </div>
        </div>
        
    </div>
  )
}

export default About
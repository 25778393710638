
'use client';

import { Button, Navbar } from 'flowbite-react';

export default function Nav() {
  return (
    <Navbar className='bg-[#030E14] border border-l-0 border-r-0 border-t-0 rounded-none border-b-white/10' fluid rounded>
      <Navbar.Brand href="https://dash.nootifyt.eu/">
        <img src="/images/logo.png" className="mr-3" alt="NootifyT" />
      </Navbar.Brand>
      <div className="flex md:order-2">
        {/* <Button className=''>Waitlist</Button> */}
        <a href="https://dash.nootifyt.eu/" className='lg:block hidden'>
          <img className='w-[140px]' src="/images/dashboard.png"/>
        </a>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse className='hover:text-[#00D3EF] transition-all h-full'>
        <Navbar.Link className='text-[#CBCBCB] font-normal text-[16px] hover:bg-white/10` border-b-white/10 lg:mr-[75px] hover:text-[#00D3EF] transition-all' href="#">Home</Navbar.Link>
        <Navbar.Link className='text-[#CBCBCB] font-normal text-[16px] hover:bg-white/10` border-b-white/10 lg:mr-[75px] hover:text-[#00D3EF] transition-all' href="#services">Our Services</Navbar.Link>
        <Navbar.Link className='text-[#CBCBCB] font-normal text-[16px] hover:bg-white/10` border-b-white/10 lg:mr-[75px] hover:text-[#00D3EF] transition-all' href="#about">About Us</Navbar.Link>
        <Navbar.Link className='text-[#CBCBCB] font-normal text-[16px] hover:bg-white/10` border-b-white/10 hover:text-[#00D3EF] transition-all' href="#faq">FAQ</Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

import React, { useEffect, useRef } from 'react'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger"

function Marquee() {
    const marqueeRef = useRef(null);


    useEffect(() => {
        const marquee = marqueeRef.current;
        const marqueeContainer = marquee.parentElement;
    
        const contentWidth = marquee.clientWidth;
    
        // Set initial position to the right to create a wrap-around effect
        gsap.set(marquee, { x: window.innerWidth });
    
        // Animation to move the marquee from right to left infinitely
        gsap.to(marquee, {
          x: -contentWidth,
          duration: 20,
          ease: 'linear',
          repeat: -1,
          onUpdate: updateOpacity,
        });
    
        function updateOpacity() {
          const containerRect = marqueeContainer.getBoundingClientRect();
          const marqueeRect = marquee.getBoundingClientRect();
    
          const center = window.innerWidth / 2;
          const distanceFromCenter = Math.abs(center - (marqueeRect.left + marqueeRect.width / 2));
    
          const opacity = 1 - (distanceFromCenter / center);
    
          marquee.style.opacity = Math.min(1, Math.max(0.3, opacity));
        }
    
        return () => {
          // Cleanup GSAP animation on component unmount
          gsap.killTweensOf(marquee);
        }
      }, []);
    
  return (
    <div className='marquee max-w-[100vw] overflow-hidden pb-[100px]'>
    <div ref={marqueeRef} className='marquee-content flex flex-row gap-x-[90px]'>
        <img className='max-w-[340px] w-full' src="/images/review-1.png"/>
        <img className='max-w-[340px] w-full' src="/images/review-2.png"/>
        <img className='max-w-[340px] w-full' src="/images/review-3.png"/>
        <img className='max-w-[340px] w-full' src="/images/review-4.png"/>
    </div>
    </div>
  )
}

export default Marquee